import request from '@/../utils/request'
const api_name = '/iotechserver/MediaStreamController'

export default {
    list() {
        return request({
            url: `${api_name}/getStreamList`,
            method: 'get'
        })
    },
    add(id,url,remark,enabledFlv,enabledHls,mode) {
        return request({
            url: `${api_name}/add`,
            method: 'post',
            data: {
                id: id,
                url: url,
                remark: remark,
                enabledFlv: enabledFlv,
                enabledHls: enabledHls,
                mode: mode
            }
        })
    }

}