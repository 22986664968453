<template>
  <div id="app" class="url">
    <div class="container">
      <div class="left">
        <!-- <div style="margin-left: 12px">
          <!--<el-row :gutter="1" type="flex" align="middle">
             <el-col :span="3">选择流快速填写：</el-col>
            <el-col :span="12">
              <el-select
                @change="changeSelectValue"
                v-model="selectValue"
                placeholder="可以从这里选择流"
                style="width: 100%"
              > 
                <el-option
                  v-for="item in tableData"
                  :key="item.url"
                  :label="item.remark + '--' + item.url"
                  :value="item.url"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>-->
          <!-- <el-row :gutter="1" type="flex" align="middle">
            <el-col :span="3">播放地址：</el-col>
            <el-col :span="12"
              ><el-input v-model="playUrl" placeholder="播放地址"></el-input
            ></el-col>
            <el-button @click="handlePlay" type="primary">播放</el-button>
          </el-row> -->

          <!-- <div style="margin: 12px">
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple-dark"></div>
              </el-col>
              <el-button type="primary" @click="handlePlay">播放</el-button>
              <el-input v-model="playUrl" placeholder="播放地址"></el-input>
            </el-row>
          </div>
        </div>-->
      </div> 
      <div style="positon:absolute; width:100%; align:center;">
        <div
          class="aaa"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            align: center;
            bottom: 0px;
            top: 80px;
            width: 70%;
          "
        >
          <!-- 视频播放位置 -->
          <div
            style="width: 100%; height: 100%; margin-left: 0px "
            v-if="selected == 0"
          >
            <el-row
              :gutter="10"
              type="flex"
              style="min-height: 1080px; min-width: 1920px"
              justify="center"
            >
              <el-col :span="24">
                <div
                  @click="clickVideo(0)"
                  :class="videoIndex == 0 ? 'selectVideo' : 'video'"
                >
                  <cus-player
                    @clickPlayer="clickPlayer"
                    ref="video0"
                  ></cus-player>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "xgplayer";
import Media1 from "@/api/mediaApi/media";
import CusPlayer from "./CusPlayer.vue";

export default {
  name: "Media",
  // props:['deviceId'],
  components: {
    CusPlayer,
  },
  data() {
    return {
      selected: 0, //表格类型
      videoIndex: 0, //表格索引
      tableData: [], //列表
      selectValue: "", //当前选择的相机
      playUrl:"",
     // "ws://192.168.18.153:50031/live?url=rtsp://admin:abx123456@192.168.18.197:554/h264/ch1/main/av_stream&&&ffmpeg=true", //播放的url
      useffmpeg: true, //使用ffmpeg
      id: 233,
      url: "",
      remark: "这是一个备注",
      enabledFlv: 0,
      enabledHls: 0,
      mode: "未开启",
      deviceId: "",
      deviceInfoList:[]
    };
  },
  created() {
    // this.list;
        //跳转后传参
    this.deviceId = this.$route.query.deviceId;
    console.log(this.$route.query.rtsp);
    this.url = this.$route.query.rtsp;
    this.playUrl = "ws://192.168.18.153:50031/live?url="+this.url+"&&&ffmpeg=true";
    console.log(this.playUrl);

    // this.readRtsp()
  },
  mounted() {
    this.handlePlay();
    // let ref = "video" + this.videoIndex;
    // this.$refs[ref].createPlayer(this.playUrl, true, this.videoIndex);

  },
  methods: {
    selectType(selected) {
      this.selected = selected;
    },

    clickVideo(index) {
      this.videoIndex = index;
    },

    // changeSelectValue() {
    //   this.playUrl = "ws://127.0.0.1:50031/live?url=" + this.selectValue;
    // },

    // changeFfmpeg() {
    //   if (!this.selectValue) {
    //     this.$message.error("请先选择流");
    //     this.useffmpeg = false;
    //     return;
    //   }
    //   if (this.useffmpeg) {
    //     this.playUrl =
    //       "ws://192.168.18.153:50031/live?url=" +
    //       this.selectValue +
    //       "&&&ffmpeg=true";
    //   } else {
    //     this.playUrl = "ws://192.168.18.153:50031/live?url=" + this.selectValue;
    //   }
    // },
    handlePlay() {
      if (this.playUrl) {
        let ref = "video" + this.videoIndex;
        this.$refs[ref].createPlayer(this.playUrl, true, this.videoIndex);
      } else {
        this.$message.error("请填写播放地址");
      }
    },

    clickPlayer(index) {
      this.videoIndex = index;
    },

    readRtsp(deviceInfoList){
      for (let i = 0; i < this.deviceInfoList.length; i++) {
            // 把deviceInfoList下的name数组里的属性添加到deviceInfoList集合里
            if (this.deviceInfoList[i].name != null) {
              for (let j = 0; j < this.deviceInfoList[i].name.length; j++) {
                if (this.deviceInfoList[i].name[j] == "rtsp") {
                  this.url = this.deviceInfoList[i].value[j]
                }
              }
            }
          }
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 30px;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;

  .left {
      width: 10%;
      height: 95%;
      box-sizing: border-box;
      margin: 0 15px;

      // padding: 15px;
      // border-radius: 10px;
      .left-content-top {
          border-radius: 10px;
          margin-top: 5px;
          width: 100%;
          height: 46.8%;
          overflow: scroll;
          background: rgba(1, 65, 70, 0.2);
          scrollbar-width: none;
          // background: #fff;
      }
  }

  .selectVideo {
    // border: solid 1px blue;
    height: 100%;
    width: 100%;
    position: relative;
  }

  .video {
    border: solid 1px #55557f;
    height: 100%;
    width: 100%;
    position: relative;
  }
}
</style>